import {IEntityPage, ILocale, IReview} from '@redux/types';

export enum EReviewActionType {
  SET_REVIEWS = 'SET_REVIEWS',
  GET_REVIEWS_PAGE = 'GET_REVIEWS_PAGE',
}

export interface IReviewState {
  page?: IEntityPage | null
  reviews?: IReview[]
}

export interface IReviewSetAction {
  type: EReviewActionType.SET_REVIEWS,
  payload: IReviewState
}

export interface IReviewPageGetAction {
  type: EReviewActionType.GET_REVIEWS_PAGE,
  payload: ILocale
}

export type IReviewActions = IReviewSetAction | IReviewPageGetAction
