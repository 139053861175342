import {CardType, IStep, IStrapiAttributes, IStrapiData} from '@redux/types'
import {call, put, takeEvery} from 'redux-saga/effects'
import api from '@api/index'
import * as actions from './actions'
import {EExpertiseActionType, IExpertisePage, IExpertisePageGetAction} from './types'
import {getStrapiQuery} from '@common/functions'
import {getSteps} from '@common/utils/getSteps'

function* getExpertisePage({payload}: IExpertisePageGetAction) {
  try {
    let query = getStrapiQuery({
      populate: 'deep,4'
    })
    const response: IStrapiData<IStrapiAttributes<any>> = yield call(() => api.get('/expertise-page', {query}))

    const data = response.data.attributes

    const steps: IStep[] = getSteps(data.steps)

    yield put(
      actions.setExpertisePage({
        expertisePage: {
          ...data,
          industries: data.industries.map((i: any) => {
            const cardType = i?.cardType?.data?.attributes?.type
            return {
              ...i,
              cardType: cardType ? (cardType as CardType) : null
            }
          }),
          steps
        } as IExpertisePage
      })
    )
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

export default [takeEvery(EExpertiseActionType.GET_EXPERTISE_PAGE, getExpertisePage)]
