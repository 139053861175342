import update from 'immutability-helper';
import {EContactsActionType, IContactsActions, IContactsState} from '@redux/contacts/types';

const initialState: IContactsState = {
  contacts: null
}

export const ContactsReducer = (state = initialState, action: IContactsActions): IContactsState => {
  switch (action.type) {
    case EContactsActionType.SET_CONTACTS:
      return update(state, {$merge: action.payload})
    case EContactsActionType.GET_CONTACTS:
      return state
    default:
      return state
  }
}
