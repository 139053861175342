import {ESolutionActionType, ISolutionActions, ISolutionDetailGetAction, ISolutionState} from '@redux/solutions/types';
import {IDetail, ILocale} from '@redux/types';


export const setSolutions = (payload: ISolutionState): ISolutionActions => ({
  type: ESolutionActionType.SET_SOLUTIONS,
  payload
})

export const getSolutionPage = (payload: ILocale): ISolutionActions => ({
  type: ESolutionActionType.GET_SOLUTIONS_PAGE,
  payload
})

export const getSolution = (payload: IDetail): ISolutionDetailGetAction => ({
  type: ESolutionActionType.GET_SOLUTION,
  payload
})
