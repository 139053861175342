import {EReviewActionType, IReviewPageGetAction, IReviewSetAction, IReviewState} from '@redux/reviews/types';
import {ILocale} from '@redux/types';


export const setReviews = (payload: IReviewState): IReviewSetAction => ({
  type: EReviewActionType.SET_REVIEWS,
  payload
})

export const getReviewsPage = (payload: ILocale): IReviewPageGetAction => ({
  type: EReviewActionType.GET_REVIEWS_PAGE,
  payload
})
