import { CardType, ILocale, IPage } from "@redux/types";

export enum EExpertiseActionType {
  GET_EXPERTISE_PAGE = "GET_EXPERTISE_PAGE",
  SET_EXPERTISE_PAGE = "SET_EXPERTISE_PAGE",
}

interface IIndustries {
  id: number;
  title: string;
  link: string;
  description: string;
  color: string;
  cardType: CardType;
}
export interface IExpertisePage extends IPage {
  industries: IIndustries[];
}

export interface IExpertiseState {
  expertisePage?: IExpertisePage | null;
}

export interface IExpertisePageGetAction {
  type: EExpertiseActionType.GET_EXPERTISE_PAGE;
  payload: ILocale;
}

export interface IExpertisePageSetAction {
  type: EExpertiseActionType.SET_EXPERTISE_PAGE;
  payload: ILocale;
}

export type IExpertiseActions = IExpertisePageGetAction;
