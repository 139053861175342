import update from 'immutability-helper';
import {EServiceActionType, IServiceActions, IServiceState} from '@redux/services/types';

const initialState: IServiceState = {
  servicePage: null,
  service: null
}

export const ServicesReducer = (state = initialState, action: IServiceActions): IServiceState => {
  switch (action.type) {
    case EServiceActionType.SET_SERVICES:
      return update(state, {$merge: action.payload})
    case EServiceActionType.GET_SERVICE:
      return state
    case EServiceActionType.GET_SERVICES_PAGE:
      return state
    default:
      return state
  }
}
