import { IDetail, ILocale } from "@redux/types";
import { EExpertiseActionType, IExpertisePageGetAction, IExpertisePageSetAction } from "./types";

export const getExpertisePage = (payload: ILocale): IExpertisePageGetAction => ({
  type: EExpertiseActionType.GET_EXPERTISE_PAGE,
  payload,
});

export const setExpertisePage = (payload: any): IExpertisePageSetAction => ({
  type: EExpertiseActionType.SET_EXPERTISE_PAGE,
  payload,
});
