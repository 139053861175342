import update from 'immutability-helper';
import {EAppActionType, IAppActions, IAppState} from '@redux/app/types';

const initialState: IAppState = {
  page: null,
  noEng: false
}

export const AppReducer = (state = initialState, action: IAppActions): IAppState => {
  switch (action.type) {
    case EAppActionType.SET_APP:
      return update(state, {$merge: action.payload})
    case EAppActionType.GET_PERSONAL_INFORMATION:
      return state
    default:
      return state
  }
}
