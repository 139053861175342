import { EProjectActionType } from "@redux/projects/types";
import update from "immutability-helper";
import { EExpertiseActionType } from "./types";

const initialState = {
  expertisePage: null,
};

export const ExpertiseReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case EExpertiseActionType.GET_EXPERTISE_PAGE:
      return update(state, { $merge: action.payload });
    case EExpertiseActionType.SET_EXPERTISE_PAGE:
      return update(state, { $merge: action.payload });
    default:
      return state;
  }
};
