import {EContactsActionType, IContactsGetAction, IContactsSetAction, IContactsState} from '@redux/contacts/types';
import {ILocale} from '@redux/types';


export const setContacts = (payload: IContactsState): IContactsSetAction => ({
  type: EContactsActionType.SET_CONTACTS,
  payload
})

export const getContacts = (payload: ILocale): IContactsGetAction => ({
  type: EContactsActionType.GET_CONTACTS,
  payload
})
