import {IEntityPage, IHomePage, ILocale} from '@redux/types';

export enum EHomeActionType {
  GET_HOME = 'GET_HOME',
  SET_HOME = 'SET_HOME',
}

export interface IHomeState {
  page?: IEntityPage | null
  home?: IHomePage | null
}

export interface IHomeSetAction {
  type: EHomeActionType.SET_HOME,
  payload: IHomeState
}

export interface IHomePageGetAction {
  type: EHomeActionType.GET_HOME
  payload: ILocale
}


export type IHomeActions = IHomeSetAction | IHomePageGetAction
