import {EHomeActionType, IHomePageGetAction, IHomeSetAction, IHomeState} from '@redux/home/types';
import {ILocale} from '@redux/types';


export const setHome = (payload: IHomeState): IHomeSetAction => ({
  type: EHomeActionType.SET_HOME,
  payload
})

export const getHome = (payload: ILocale): IHomePageGetAction => ({
  type: EHomeActionType.GET_HOME,
  payload
})
