import update from 'immutability-helper';
import {ESolutionActionType, ISolutionActions, ISolutionState} from '@redux/solutions/types';

const initialState: ISolutionState = {
  solution: null
}

export const SolutionsReducer = (state = initialState, action: ISolutionActions): ISolutionState => {
  switch (action.type) {
    case ESolutionActionType.SET_SOLUTIONS:
      return update(state, {$merge: action.payload})
    case ESolutionActionType.GET_SOLUTION:
      return state
    case ESolutionActionType.GET_SOLUTIONS_PAGE:
      return state
    default:
      return state
  }
}
