import update from 'immutability-helper';
import {EProjectActionType, IProjectsActions, IProjectsState} from '@redux/projects/types';

const initialState: IProjectsState = {
  projects: [],
  project: null,
  isFetchingLoading: false,
  projectsPage: null,
  selectedTagSlug: null
}

export const ProjectsReducer = (state = initialState, action: IProjectsActions): IProjectsState => {
  switch (action.type) {
    case EProjectActionType.SET_PROJECTS:
      return update(state, {$merge: action.payload})
    case EProjectActionType.SELECT_TAG:
      return update(state, {$merge: {selectedTagSlug: action.payload}})
    default:
      return state
  }
}
