import update from 'immutability-helper';
import {EAwardActionType, IAwardActions, IAwardState} from '@redux/awards/types';

const initialState: IAwardState = {
  awards: []
}

export const AwardsReducer = (state = initialState, action: IAwardActions): IAwardState => {
  switch (action.type) {
    case EAwardActionType.SET_AWARDS:
      return update(state, {$merge: action.payload})
    case EAwardActionType.GET_AWARDS_PAGE:
      return state
    default:
      return state
  }
}
