import update from 'immutability-helper';
import {EBlogsActionType, IBlogsActions, IBlogState} from '@redux/blogs/types';

const initialState: IBlogState = {
  page: null,
  blogs: [],
  blog: null,
  selectedTagSlug: null,
  tags: []
}

export const BlogsReducer = (state = initialState, action: IBlogsActions): IBlogState => {
  switch (action.type) {
    case EBlogsActionType.SET_BLOGS:
      return update(state, {$merge: action.payload})
    case EBlogsActionType.GET_BLOG_PAGE:
      return state
    case EBlogsActionType.GET_BLOG:
      return state
    default:
      return state
  }
}
