import * as process from 'process';

// backend
const auth = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken'
}

const api = {
  methodsWithBody: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
  backendUrl: process.env.NEXT_PUBLIC_BACKEND_URL || '',
  refreshTokenUrl: process.env.NEXT_PUBLIC_REFRESH_TOKEN_URL || '',
  url: `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}${process.env.NEXT_PUBLIC_API_PATH || ''}`,
}

const project = {
  name: process.env.NEXT_PUBLIC_PROJECT_NAME || 'mobile up',
  draftMode: process.env.NEXT_PUBLIC_DRAFT_MODE === 'true',
  siteUrl: process.env.NEXT_PUBLIC_SITE_URL || 'https://mobileup.ru'
}

const keys = {
  ymId: process.env.NEXT_PUBLIC_YM_ID ? Number(process.env.NEXT_PUBLIC_YM_ID) : null,
  yMapsApiKey: process.env.NEXT_PUBLIC_YMAPS_API_KEY ?? '',
  gaId: process.env.NEXT_PUBLIC_GA_ID ?? null,
  gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? null
}

export const settings = {
  auth,
  api,
  project,
  keys
}
