import {IDetail, IEntityPage, ILocale, ISolutionDetail} from '@redux/types';

export enum ESolutionActionType {
  GET_SOLUTIONS_PAGE = 'GET_SOLUTIONS_PAGE',
  GET_SOLUTION = 'GET_SOLUTION',
  SET_SOLUTIONS = 'SET_SOLUTIONS',
}

export interface ISolutionState {
  page?: IEntityPage
  solution?: ISolutionDetail | null
}

export interface ISolutionSetAction {
  type: ESolutionActionType.SET_SOLUTIONS
  payload: ISolutionState
}

export interface ISolutionPageGetAction {
  type: ESolutionActionType.GET_SOLUTIONS_PAGE
  payload: ILocale
}

export interface ISolutionDetailGetAction {
  type: ESolutionActionType.GET_SOLUTION
  payload: IDetail
}

export type ISolutionActions = ISolutionSetAction | ISolutionPageGetAction | ISolutionDetailGetAction
