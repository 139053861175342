import update from 'immutability-helper';
import {ETagActionType, ITagsActions, ITagsState} from '@redux/tags/types';

const initialState: ITagsState = {
  tags: [],
  tag: null,
  isFetchingLoading: false
}

export const TagsReducer = (state = initialState, action: ITagsActions): ITagsState => {
  switch (action.type) {
    case ETagActionType.SET_TAGS:
      return update(state, {$merge: action.payload})
    default:
      return state
  }
}
