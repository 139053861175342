import {ILocale} from '@redux/types';
import {ENewsActionType, INewsPageGetAction, INewsSetAction, INewsState} from '@redux/news/types';


export const setNews = (payload: INewsState): INewsSetAction => ({
  type: ENewsActionType.SET_NEWS,
  payload
})

export const getNewsPage = (payload: ILocale): INewsPageGetAction => ({
  type: ENewsActionType.GET_NEWS_PAGE,
  payload
})
