import update from 'immutability-helper';
import {ENewsActionType, INewsActions, INewsState} from '@redux/news/types';

const initialState: INewsState = {
  page: null,
  news: []
}

export const NewsReducer = (state = initialState, action: INewsActions): INewsState => {
  switch (action.type) {
    case ENewsActionType.SET_NEWS:
      return update(state, {$merge: action.payload})
    case ENewsActionType.GET_NEWS_PAGE:
      return state
    default:
      return state
  }
}
