import update from 'immutability-helper';
import {EReviewActionType, IReviewActions, IReviewState} from '@redux/reviews/types';

const initialState: IReviewState = {
  page: null,
  reviews: []
}

export const ReviewsReducer = (state = initialState, action: IReviewActions): IReviewState => {
  switch (action.type) {
    case EReviewActionType.SET_REVIEWS:
      return update(state, {$merge: action.payload})
    case EReviewActionType.GET_REVIEWS_PAGE:
      return state
    default:
      return state
  }
}
