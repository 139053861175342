import update from 'immutability-helper';
import {EVacancyActionType, IVacanciesActions, IVacanciesState} from '@redux/vacancies/types';

const initialState: IVacanciesState = {
  vacancies: [],
  vacancy: null,
  isFetchingLoading: false,
  vacanciesPage: null
}

export const VacanciesReducer = (state = initialState, action: IVacanciesActions): IVacanciesState => {
  switch (action.type) {
    case EVacancyActionType.SET_VACANCIES:
      return update(state, {$merge: action.payload})
    default:
      return state
  }
}
