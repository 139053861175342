import axios, {AxiosRequestConfig, Method} from 'axios';
import {settings} from '@common/settings';
import {setRequestParams} from '@common/functions';
import {ICookieOptions, IParam} from '@common/types';
import {getCookie} from 'cookies-next';
import {COOKIE_OPTIONS} from '@common/constants';
import {GetServerSidePropsContext} from 'next';


async function base(
  method: Method,
  url: string,
  params: {
    query?: IParam,
    body?: object,
    headers?: IParam,
    ctx?: GetServerSidePropsContext
  }
) {
  const instance = axios.create()
  const cookieOptions: ICookieOptions = params?.ctx ? {req: params.ctx.req, res: params.ctx.res} : {}
  const cookieSetOptions: ICookieOptions = params?.ctx ? {...cookieOptions, ...COOKIE_OPTIONS} : COOKIE_OPTIONS
  const token = getCookie(settings.auth.accessToken, cookieOptions)

  let headers: any = params?.headers ? params.headers : {
    accept: 'application/json'
  }

  if (token && !params?.headers?.Authorization) {
    headers.Authorization = `Bearer ${token}`
  }

  const request: AxiosRequestConfig = {
    method,
    url: `${settings.api.url}${url}${setRequestParams(params?.query)}`,
    data: settings.api.methodsWithBody.includes(method.toUpperCase()) ? params?.body : null,
    headers
  }

  instance.interceptors.request.use(config => {
    return {...config}
  })

  instance.interceptors.response.use(async (response) => {
    return response?.data
  }, async (error) => {
    const statusCode = error?.response?.status

    return Promise.reject({
      status: statusCode,
      code: error?.response?.data?.error?.code || null,
      message: error?.response?.data?.error?.message || null,
      data: error?.response?.data?.error?.data || null,
      error: error?.response || null
    })
  })

  return instance.request(request)
}

export default base
