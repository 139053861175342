import update from 'immutability-helper';
import {EHomeActionType, IHomeActions, IHomeState} from '@redux/home/types';

const initialState: IHomeState = {
  page: null,
  home: null
}

export const HomeReducer = (state = initialState, action: IHomeActions): IHomeState => {
  switch (action.type) {
    case EHomeActionType.SET_HOME:
      return update(state, {$merge: action.payload})
    case EHomeActionType.GET_HOME:
      return state
    default:
      return state
  }
}
