import base from './base';
import {Method} from 'axios';

const methods: Method[] = ['get', 'post', 'put', 'patch', 'delete']
const api: any = {}

methods.forEach(method => {
  api[method] = base.bind(null, method)
})

export default api
