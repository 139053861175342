import {EBlogsActionType, IBlogGetAction, IBlogPageGetAction, IBlogsSetAction, IBlogState} from '@redux/blogs/types';
import {IDetail} from '@redux/types';


export const setBlogs = (payload: IBlogState): IBlogsSetAction => ({
  type: EBlogsActionType.SET_BLOGS,
  payload
})

export const getBlogPage = (): IBlogPageGetAction => ({
  type: EBlogsActionType.GET_BLOG_PAGE,
})

export const getBlog = (payload: IDetail): IBlogGetAction => ({
  type: EBlogsActionType.GET_BLOG,
  payload
})
